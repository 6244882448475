@use "@/styles/_colors";
@use "@/styles/_typography";
@use "@/styles/_breakpoint";
.tag {
  cursor: pointer;
  -webkit-user-select: none; /* Safari */        
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+/Edge */
user-select: none; /* Standard */
  z-index: 200;
  border-radius: 50%;
  position: absolute;
  display: flex;
 
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  flex-direction: column;
  justify-content: center;
 
  align-items: center;
  transform: translate(-50%, -50%);
  flex-wrap: nowrap;
  transition: opacity 100ms ease;
 
  .plus{
    position: absolute;
    top: 0;
   display: block;
   opacity: 100%;
   &:hover{
    opacity: var(--hover-opacity)
   }
  
   &.selected{
    
    opacity: 0;
    &:hover{
      opacity: 0;
    }
   
   }

 

  
  }
  .minus{
    position: absolute;
    top: 0;
    transition: all 50ms ease;
    display: block;
    opacity: 0;
    transform: rotate(-45deg);
    &.selected{
      opacity: 100;
      transform: rotate(0);
      &:hover{
        opacity: var(--hover-opacity)
       }
     
    }
   
  }

  &.inactive{
    opacity: 70%;
    z-index: 100;
  }

  .info{
    &:hover{
      opacity: 1;
    }
    z-index: 210;
    position: absolute;
    text-decoration: none;
    background-color: white;
    gap: 15px;
  display: flex;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
border-radius: 2px;
    padding: 5px;
    width: 300px;
    .tagWrapper{
      position: relative;
      height: 97px;
      width: 74px;
    }
    .infoWrapper{
      padding: 5px 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .nameContainer{
        @include typography.b14-12;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        .mainCategory{
          
          color: colors.$black06;
        }
      
      }
      
    .variantName{
     @include typography.b12-10;
    }
  
    .price {
      @include typography.b14-12;
      display: flex;
      flex-direction: column;
      
      .discountPrice{
        @include typography.b12-12;
        color: colors.$black06;
        text-decoration: line-through;
      }
    }
    .arrow{
      position: absolute;
     bottom: 10px;
      right: 15px;
      transform: rotate(90deg);
      
    
    }
  }
  }
 
}

@include breakpoint.mobile {
 
.info{
  display: none;
}
  
  
}