@use "@/styles/_breakpoint";
@use "@/styles/_grid";
@use "@/styles/_typography";
@use "@/styles/_colors";

.container {
  @include grid.mainGrid;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 80px;
  background-color: var(--header-color,colors.$sand);
  .logo {
    grid-column: 1 / span 5;
    height: 100%;
    display: flex;
  }
  .subContainer {
    @include grid.subGrid(12);
    grid-column: span 12;
    grid-row: 2;
    margin-top: 77px;
    .currencySelector {
      grid-column: 6 / span 2;
      display: flex;
      align-items: center;
    }
    .legal {
      @include typography.b14-14;
      grid-column: 10 / span 2;
      grid-row: 1;
      display: flex;
      align-items: center;
      gap: 10px;
      color: colors.$black06;
    }
    .socialMedia {
      @include typography.b14-14;
      grid-row: 1;
      grid-column: 8 / span 2;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

@include breakpoint.mobile {
  .container {
    
    padding-bottom: 45px;
    padding-top: 15px;
    .currencySelector {
      padding: 15px 0;
      grid-column: span 4;
    }
    .subContainer {
     display: flex;
     flex-wrap: wrap;
     row-gap: 16px;
      grid-column: span 4;
      grid-row: inherit;
      margin-top: 50px;
     
      .legal {
        grid-column: span 4;
        a{
          color: #676767;
          }
      }
      .socialMedia {
        grid-column: span 4;
        a{
        color: #676767;
        }
      }

    }
  }
}