@use "@/styles/_grid.scss";


//animation variables

//vertical
$verticalTranslation: calc(grid.$desktopGap );
$verticalTranslationMobile: grid.$mobileColumnSize;

@keyframes skeleton-loading {
  0% {
    background-color: #EDECE7;
  }
  100% {
    background-color: #FFFEFA;
  }
}


// animation fade in bottom
@keyframes fadeInBottom {
  0% {
    opacity: 0;
    
    transform: translateY($verticalTranslation);
  }
  80% {
    opacity: 1;
   
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

// animation fade out bottom
@keyframes fadeOutBottom {
  0% {
    transform: translateY(0%);
   
  }
  20% {
    opacity: 1;
   
  
  }
  100% {
    opacity: 0;
   
    transform: translateY($verticalTranslation);
  }
}

// animation fade in top
@keyframes fadeInTop {
  0% {
    opacity: 0;
   
    transform: translateY(calc(-1 * $verticalTranslation));
  }
  80% {
    opacity: 1;
   
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
    
  }
}


// animation fade out top
@keyframes fadeOutTop {
  0% {
    transform: translateY(0%);
    
  }
  20% {
    opacity: 1;
   
  }
  100% {
    opacity: 0;
    

    transform: translateY(calc(-1 * $verticalTranslation));
  }
}

// animation fade in right

@keyframes fadeInRight{
  0% {
    opacity: 0;
    transform: translateX(calc($verticalTranslation * 5));
    
  }
  100% {
    opacity: 1;
   
    transform: translateX(0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
   
  }
  100% {
    opacity: 0;
   
    transform: translateX(calc($verticalTranslation * 5));
  }
}

// animation fade in left

@keyframes fadeInLeft {
  0% {
    opacity: 0;
   
    transform: translateX(calc(-1 * calc($verticalTranslation * 5)));
  }
  100% {
    opacity: 1;
   
    transform: translateX(0);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
   
  }
  100% {
    opacity: 0;
   
    transform: translateX(calc(-1 * calc($verticalTranslation * 5)));
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
   
  }
  100% {
    opacity: 1;
   
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
   
  }
  100% {
    opacity: 0;
   
  }
}