@use "@/styles/_devColors.scss";
@use "@/styles/_breakpoint.scss";

.toolkit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9990;
  pointer-events: none;
}

.menu {
  z-index: 9999;
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  pointer-events: all;
}

.subMenu {
  width: 300px;
  padding: 10px;
  background-color: devColors.$darkPurple;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    color: devColors.$lightPurple;

    p {
      color: devColors.$lightPurple;
    }

    .checkbox {
      height: 16px;
      width: 16px;
      border: 2px solid devColors.$lightPurple;
    }

    &.active {
      color: devColors.$brandOrange;

      p {
        color: devColors.$brandOrange;
      }

      .checkbox {
        border: unset;
        background-color: devColors.$brandOrange;
      }
    }
  }
}
