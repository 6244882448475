@use "@/styles/_media.scss";
@use "@/styles/_typography.scss";
@use "@/styles/_grid.scss";
@use "@/styles/_colors.scss";
@use "@/styles/_breakpoint.scss";

$paddingX: calc(grid.desktopFauxColumnWidth(1) + grid.$desktopPadding + grid.$desktopGap);
$gap: calc((grid.desktopFauxColumnWidth(1) + (grid.$desktopGap * 2)) / 2);



.container{
  display: flex;
  flex-direction: column;
  padding: 160px $paddingX;
  gap: 60px;
  .title{
    @include typography.h32-22;
    text-align: center;
    display: block;
    width: 100%;
    text-decoration: none;
    
  }
  a{
    &.title{
    &:hover{
      
      text-decoration: underline;
    }
  }
  }
  .wrapper{
    display: flex;
   
    justify-content: center;
    gap: $gap;
    
  }
}

@include breakpoint.mobile {
  .container{
    padding: 0;
    margin: 32px 20px;
    gap: 24px;
  
    .wrapper{
      
      overflow-x: scroll; 
      overflow-y: hidden;
      flex-direction: column;
      gap: 32px;
      &.three{
      flex-direction: row;

      justify-content: flex-start;
      gap: 10px;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;  /* Chrome, Safari, Opera*/
      }
    }
    }
  }
}