@use "@/styles/_colors.scss";
@use "@/styles/_typography.scss";
@use "@/styles/_breakpoint.scss";

.container {
  margin-left: auto;
  

  .sortMenu {
    position: relative;
  }

  .button {
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;

    svg {
      transform: rotate(90deg);
    }
  }

  
}

.dropdown {
  display: flex;
  flex-direction: column;
  padding: 24px;

  gap: 15px;
  right: 0;
  background-color: colors.$white;
  border-radius: 8px;
  min-width: 182px;
  border: 0;

  transition: all 0.2s ease-in-out;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &[open], &[data-open="true"] {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
  z-index: 999;
  color: colors.$teal;
 
.item{
@include typography.b14-12;
&:not(:last-child) {
  border-bottom: 1px solid colors.$black05;
  padding-bottom: 7px;;
}
  &.selected {
    color: colors.$black;
    display: flex;
    justify-content: space-between;
  }
}
}

// .overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100vh;
 
// }

@include breakpoint.mobile {
  .container {
    .dropdown {
      padding: 16px;
      left: 0;
      right: auto;
    }
  }
}

