
.fit{
  position: relative;
  width: fit-content;
  height: fit-content;

 
}

.fill{
  position: relative;
  width: 100%;
  height: 100%;

}