@use "@/styles/_breakpoint";
@use "@/styles/_grid";
@use "@/styles/_media";
@use "@/styles/_typography";
@use "@/styles/_colors";
.container {
  padding: 20px;

  .submenu {
    display: flex;
    flex-direction: column;

    .space {
      height: 30px;
    }
  }
}