@use "@/styles/colors";
@use "@/styles/typography";

$defaultTextColor: colors.$black;

:root{
  --hover-opacity: 90%;
}

@font-face {
  font-family: 'TWKLausanne';
  src: url('/fonts/TWKLausanne-300.woff');
  src:  url('/fonts/TWKLausanne-300.woff2') format('woff2'), url('/fonts/TWKLausanne-300.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}



html {
  font-size: 62.5%; 

}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body,
input,
table,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  font-family: 'TWKLausanne', sans-serif;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 1.6rem;
  background-color: colors.$sand;
}

h1 {
  margin: 0;
  font-size: inherit;
}

h3 {
  margin: 0;
}

h4 {
  margin: 0;
}

h5 {
  margin: 0;
}

h6 {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

input,
textarea {
  outline: none;
}

button {
  all: unset;
  cursor: pointer;
  outline: none;
}

img {
  border: none;
  vertical-align: middle;
}

svg {
  vertical-align: middle;
}

h1, h2, h3, h4, h5, h6, p, a, span, button {
  color: $defaultTextColor;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, 
  *:before,
  *:after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


.adyen-checkout__payment-method__details__content{
  .adyen-checkout__button.adyen-checkout__button--loading{
    background-color: colors.$brown;
  }
  .adyen-checkout__button{ 
    &.adyen-checkout__button--loading{
    background-color: colors.$brown;
  }}
  .adyen-checkout__spinner__wrapper{
    background: colors.$brown;
  }
 .adyen-checkout__button--pay {
  

  @include typography.b16-14;
  background-color: colors.$brown;
  color: colors.$sand;
  border-radius: 2px;

  
  &:hover{
    background: colors.$brownHover;
    box-shadow: none;
  }
  &:active{
    background: colors.$buttonClick;
    box-shadow: none;
  }
  
}

.adyen-checkout__button__text{
  font-weight: 500;
  color: colors.$sand;
}
}

.adyen-checkout__field{
  .adyen-checkout__label{
    .adyen-checkout__label__text{
      color: colors.$black;
    font-weight: 500;
  }
  }
}

.adyen-checkout__payment-method--selected, .adyen-checkout__payment-method{
background: colors.$sand !important;
border: none !important;
}


.adyen-checkout__payment-method__header {
  .adyen-checkout__payment-method__radio,
  .adyen-checkout__payment-method__radio--selected{
  background: none ;


  border: 1px solid colors.$black06;

  &::after {
    background: colors.$brown;
    width: 10px;
    height: 10px;
  }


  }
}

.css-1okebmr-indicatorSeparator {
background-color: colors.$black06 !important;
}

.css-tlfecz-indicatorContainer {
  color: colors.$black06 !important;
}
