@use "@/styles/_breakpoint";
@use "@/styles/_colors";

.button {

  position: relative;
  svg {
    height: 24px;
    width: 24px;
    path {
      stroke: black;
      stroke-width: 1;
    }
  }
}

@include breakpoint.mobile {
  .button {
    padding-top: 10px;
    font-size: 1.4rem;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap:4px;


  }
}
