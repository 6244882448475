@use "@/styles/_grid";
@use "@/styles/_breakpoint";

:export {
  desktopColumns: grid.$desktopColumnCount;
  mobileColumns: grid.$mobileColumnCount;
}

$overlayColor: #00ffff;
$borderStyle: 1px solid $overlayColor;

.container {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.outOfBounds {
  flex-shrink: 1;
  flex-grow: 0;
  width: 50%;
  background-color: #ff00ff;
  margin: 0 calc(-1 * grid.$desktopPadding);

  &.edge {
    background-color: unset;
  }
}

.grid {
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  padding: 0 grid.$desktopPadding;
  display: grid;
  grid-template-columns: repeat(grid.$desktopColumnCount - 1, 1fr grid.$desktopGap) 1fr;
}

.column {
  width: 100%;
  height: 100%;

  &.overlay {
    background-color: $overlayColor;
    &.edge {
      background-color: unset;
      border-left: $borderStyle;
      border-right: $borderStyle;
    }
  }
}

@include breakpoint.mobile {
  .outOfBounds {
    margin: 0 calc(-1 * grid.$mobilePadding)
  }

  .grid {
    padding: 0 grid.$mobilePadding;
    grid-template-columns: repeat(grid.$mobileColumnCount - 1, 1fr grid.$mobileGap) 1fr;
  }
}

