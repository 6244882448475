@use "@/styles/_colors";
@use "@/styles/_typography";
@use "@/styles/_breakpoint";
  .info{
    z-index: 900;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 20px;
    padding-bottom: 30px;
    padding-top: 60px;
    gap: 14px;
    left: 0px;

    display: flex;
    flex-direction: column;
    background-color: colors.$cream;
    .closeButton{
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
      padding: 20px;
      @include typography.b12-12;    
    }
    .mainWrapper{
        gap: 16px;
        flex-direction: column;
    display: flex;
    .firstRow{
      @include typography.b12-12;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    .nameContainer{
      @include typography.b12-12;
      gap: 4px;
      display: flex;
      .mainCategory{
        color: colors.$black06;
      }

    }
  }
.variantName{
  @include typography.b12-10;
}
    .imageCarousel{
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 5px;
      overflow: scroll;
      .tagWrapper{
        position: relative;
       padding-bottom: 62.5%;
        width: 50%;
      }
    }
      }
    .button{
      @include typography.b14-14;
      background-color: colors.$brown;
      padding: 8px 16px;
      border-radius: 2px;
      color: colors.$sand;
      width: fit-content;
  }
}
.price{
  .discountPrice{
    @include typography.b12-12;
    color: colors.$black06;
    text-decoration: line-through;
  }
}

.subContainer{
  display: flex;
  justify-content: space-between;

  .wishlistButton{
    @include typography.b12-12;

  }
}
