/* General interactions, can be overiden in components */
.header,
.footer {
  a,
  button {
    &:hover {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
}
.site-content {
  --minimum-page-height: calc(100svh - var(--header-height, 110px) - var(--footer-height, 388px));
  min-height: var(--minimum-page-height);
  a {
    text-decoration: underline;
    text-underline-offset: 2px;

    &:hover{
      opacity: var(--hover-opacity)
    }
  }
}