@use "@/styles/_colors";
@use "@/styles/_grid";
@use "@/styles/_media";
@use "@/styles/_breakpoint";

.container {
  display: flex;
  align-items: center;
  padding: 96px
    calc(
      grid.desktopFauxColumnWidth(2) + grid.$desktopPadding +
        calc(grid.$desktopGap * 1)
    );
  gap: calc(grid.desktopFauxColumnWidth(1) + calc(grid.$desktopGap * 2));

  .media {
    @include grid.desktopFauxColumnSpan(4);

    .taggedImage {
      @include media.mediaWrapper4-5;
    }
    .media {
      @include media.mediaWrapper4-5;
    }
    &.flip {
      order: 1;
    }
  }

  .product {
    @include grid.desktopFauxColumnSpan(3);
    padding-top: calc(grid.desktopFauxColumnWidth(1) / 2);
    &.flip {
      order: -1;
    }
  }
}
$firstImageWidthMobile: grid.mobileFauxColumnWidth(4);
$secondImageWidthMobile: grid.mobileFauxColumnWidth(3);
@include breakpoint.mobile {
  .container {
    padding: 32px 20px;
    width: 100vw;
    gap: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .media {
      width: $firstImageWidthMobile;
      min-width: $firstImageWidthMobile;
      max-width: $firstImageWidthMobile;
      .taggedImage {
        @include media.mediaWrapper4-5;
      }
      .media {
        @include media.mediaWrapper4-5;
      }
    }
    .product {
    
    @include grid.mobileFauxColumnSpan(3);
    .productCard {
      width: $secondImageWidthMobile;
      min-width: $secondImageWidthMobile;
      max-width: $secondImageWidthMobile;
    }
    
  }
  }
}
