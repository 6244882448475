@use "@/styles/_breakpoint";
@use "@/styles/_grid";
@use "@/styles/_colors";
@use "@/styles/_typography";

.container {
 @include grid.mainGrid;
  width: 100%;
  padding-top: 30px;
  justify-content: space-between;    
  align-items: flex-start;
  z-index: 700;
  background-color: var(--header-color,colors.$sand);
  top: 0;
  position: sticky;
  padding-bottom: 30px;
  
  .menuIcon {
    @include typography.b14-14;
    padding-top: 10px;
  }

  .logoWrapper {
    grid-column: 6 / span 2;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  transition: ease-in-out top 0.5s;

  &.hide {
    top: -110px;
  }
}

@include breakpoint.mobile {
  .container {
    grid-template-columns: 1fr auto 1fr;
    padding: 20px;
    padding-top: 10px;

    .navigation {
      display: flex;
      flex-direction: column;
    }
    .logoWrapper {
      grid-column: span 1;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
  .featuredLinks {
    grid-row: 2;
    grid-column: 1 / span 3;
    @include typography.b14-14(true);
    order: 1;
    display: flex;
    gap: 12px;
    left: 0;
    padding-top: 32px;
    width: 100%;
    color: colors.$teal;
    .selected {
      @include typography.underline;
      color: colors.$black;
    
   
    }
  }
 
}