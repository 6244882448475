@use "@/styles/typography";

.container{
  @include typography.b14-14;
  height: 100%;
  grid-column: 8/ span 5;
  display: flex;
  justify-content: flex-end;    
  align-items: center;
  gap: 30px
}