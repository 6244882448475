
@use "@/styles/_breakpoint.scss";
.packShot {
  opacity: 1;


  @include breakpoint.desktop {
  &:hover {
    opacity: 0;
  }

}
 
}






