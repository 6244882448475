@use "@/styles/_breakpoint";
@use "@/styles/_grid";
@use "@/styles/_media";
@use "@/styles/_typography";
@use "@/styles/_colors";
.container{
  @include typography.b14-14;

.submenu{
  display: flex;
  position: absolute;
  left: 0;
  bottom: -23px;
  gap: 15px;
  color: colors.$black08;
 
  
  .selected{
    @include typography.underline;
    color: colors.$black;
  }
}
}


  @include breakpoint.mobile{
    .modal{
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: white;
    
    }
  }

  
