@use "@/styles/_media.scss";
@use "@/styles/_typography.scss";
@use "@/styles/_grid.scss";
@use "@/styles/_breakpoint.scss";

.container{
  cursor: pointer;
  text-decoration: none;
  display: flex;
  position: relative;
  flex-direction: column;
  @include grid.desktopFauxColumnSpan(3);
  &.two{
    @include grid.desktopFauxColumnSpan(5);
    text-align: center;
  .abstract{
    padding-left: 15px;
    padding-right: 15px;
  }
   &.first{
    .mediaWrapper{
     @include grid.desktopFauxColumnSpan(5);
     .media{
       @include media.mediaWrapper3-2
     }
   }
  }
    &.second{
      @include grid.desktopFauxColumnSpan(4);
      .mediaWrapper{
      @include grid.desktopFauxColumnSpan(4);
      .media{
        @include media.mediaWrapper3-2
      }
    }
  }
}

  .mediaWrapper{
    @include grid.desktopFauxColumnSpan(3);
    display: flex;
    .media{
      @include media.mediaWrapper4-5;
      &:hover{
        opacity: var(--hover-opacity);
        cursor: pointer;
      }
    }
  }
  .subHeader{
    margin-top: 19px; 
    @include typography.b14-12;
  }
  .header{
    @include typography.h20-16;
  }
  .abstract{
    margin-top: 9px; 
    @include typography.b16-14;
  }
  .link{
    @include typography.b16-14;
    margin-top: 9px;
    text-decoration: underline;

    text-underline-offset: 3px;
    &:hover{
      opacity: var(--hover-opacity);
      cursor: pointer;
    }
  }
}


@include breakpoint.mobile {
  .container{
    flex-direction: column;
    @include grid.mobileFauxColumnSpan(4);
    &.two{
     
     @include grid.mobileFauxColumnSpan(4);
     &.first{
      .mediaWrapper{
        @include grid.mobileFauxColumnSpan(4);
       .media{
         @include media.mediaWrapper3-2
       }
     }
    }
      &.second{
        @include grid.mobileFauxColumnSpan(4);
        .mediaWrapper{
          @include grid.mobileFauxColumnSpan(4);
        .media{
          @include media.mediaWrapper3-2
        }
      }
    }
  }
  &.three{
    width: calc(grid.mobileFauxColumnWidth(3) + grid.$mobileGap);
    min-width: calc(grid.mobileFauxColumnWidth(3) + grid.$mobileGap);
    max-width: calc(grid.mobileFauxColumnWidth(3) + grid.$mobileGap);
  }



  .mediaWrapper{
    @include grid.mobileFauxColumnSpan(4);
    &.two{
      .subHeader{
    text-align: center;
      }
      .header{
        text-align: center;
      }
      .abstract{
        text-align: center;
      }
      .link{
        text-align: center;
      }
    }
    &.three{
      width: calc(grid.mobileFauxColumnWidth(3) + grid.$mobileGap);
      min-width: calc(grid.mobileFauxColumnWidth(3) + grid.$mobileGap);
      max-width: calc(grid.mobileFauxColumnWidth(3) + grid.$mobileGap);
    }
    display: flex;
    .media{
      @include media.mediaWrapper4-5;
     
    }
  }
  }


}

