@use "@/styles/_colors";
@use "@/styles/_grid";
@use "@/styles/_media";
@use "@/styles/_breakpoint";
@use "@/styles/_typography";


.container{

@include grid.mainGrid;
width: 100vw;
height: 100%;
min-height: 100vh;
padding-top: calc( breakpoint.$headerHeight +  160px);


.form{
  grid-column: 2/ span 10;

  .input{
    @include typography.h48-16;
    width: 100%;
   
    border: none;
    border-bottom: 1px solid colors.$black;
    
    
    padding-bottom: 10px;
    margin-bottom: 32px;

    &::placeholder {
      color: colors.$black06;
      opacity: 1; /* Firefox */
    }

  }
}

}


@include breakpoint.mobile {
  .container{
    padding-top: calc( breakpoint.$headerHeight +  80px);
    .form{
      grid-column: span 4;
    }
  }
}