.container {
  position: relative;
  height: 100%;
  width: 100%;

  .video {
    overflow: hidden;
  }
}

.videobg {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.videobgWidth {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.videoWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & video,
  & > * {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}