@use "@/styles/_breakpoint";

$desktopGap: 30px;
$desktopPadding: breakpoint.$paddingDesktop;
$desktopColumnCount: 12;
$desktopGridSize: calc(100vw - (2 * $desktopPadding));
$desktopGridWithoutGutters: calc(
  $desktopGridSize - (($desktopColumnCount - 1) * $desktopGap)
);
$desktopColumnSize: calc($desktopGridWithoutGutters / $desktopColumnCount);

@function desktopFauxColumnWidth($columns) {
  @return calc(($columns * $desktopColumnSize) + (($columns - 1) * $desktopGap));
}

@mixin desktopFauxColumnSpan($columns) {
  width: desktopFauxColumnWidth($columns);
  max-width: desktopFauxColumnWidth($columns);
  min-width: desktopFauxColumnWidth($columns);
}

@mixin desktopFauxColumnSpanPlusGutter($columns) {
  width: calc(desktopFauxColumnWidth($columns) + $desktopGap);
  max-width: calc(desktopFauxColumnWidth($columns) + $desktopGap);
}

@mixin desktopFauxColumnSpanPlusBothGutters($columns) {
  width: calc(desktopFauxColumnWidth($columns) + (2 * $desktopGap));
  max-width: calc(desktopFauxColumnWidth($columns) + (2 * $desktopGap));
}

@function desktopHeight($columns, $ratio) {
  $width: calc(($columns * $desktopColumnSize) + (($columns - 1) * $desktopGap));
  $height: calc($width / $ratio);
  @return $height;
}

@function desktopHalfHeight($columns, $ratio) {
  $height: desktopHeight($columns, $ratio);
  $res: calc($height / 2);
  @return $res;
}


$mobileGap: 15px;
$mobilePadding: breakpoint.$paddingMobile;
$mobileColumnCount: 4;
$mobileGridSize: calc(100vw - (2 * $mobilePadding));
$mobileGridWithoutGutters: calc(
  $mobileGridSize - (($mobileColumnCount - 1) * $mobileGap)
  );
$mobileColumnSize: calc($mobileGridWithoutGutters / $mobileColumnCount);

@function mobileFauxColumnWidth($columns) {
  @return calc(($columns * $mobileColumnSize) + (($columns - 1) * $mobileGap));
}

@mixin mobileFauxColumnSpan($columns) {
  width: mobileFauxColumnWidth(($columns));
  max-width: mobileFauxColumnWidth(($columns));
  min-width: mobileFauxColumnWidth(($columns));
}
    
@function mobileHeight($columns, $ratio) {
  $width: calc(($columns * $mobileColumnSize) + (($columns - 1) * $mobileGap));
  $height: calc($width / $ratio);
  @return $height;
}
  
@function mobileHalfHeight($columns, $ratio) {
  $height: desktopHeight($columns, $ratio);
  $res: calc($height/2);
  @return $res;
}

@mixin mainGrid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: $desktopGap;
  row-gap: 0;
  padding: 0 $desktopPadding;

  @include breakpoint.mobile {
    grid-template-columns: repeat(4, 1fr);
    column-gap: $mobileGap;
    row-gap: 0;
    padding: 0 $mobilePadding;
  }
}

@mixin subGrid($columns, $mobileColumns: $columns) {
  display: grid;
  column-gap: $desktopGap;
  grid-template-columns: repeat( $columns, 1fr );

  @include breakpoint.mobile {
    column-gap: $mobileGap;
    grid-template-columns: repeat( $mobileColumns, 1fr );
  }
}


