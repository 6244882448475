@use "@/styles/_colors";
.container{
  width: 100%;
  height: 40px;
  background-color: colors.$red;
  padding: 5px;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  gap: 20px;

  & > p, a {
    color: white;
  }

  & > a {
    text-decoration: underline;
  }

}

