@use "@/styles/_colors";
@use "@/styles/_grid";
@use "@/styles/_media";
@use "@/styles/_breakpoint";


.bigImageContainer{
  display: flex;

  align-items: center;
  justify-content: center;
  padding: 96px  calc(grid.desktopFauxColumnWidth(1) + grid.$desktopPadding );
  &.large{
    padding: 120px  calc(grid.desktopFauxColumnWidth(1) + grid.$desktopPadding );
    margin: 56px 0;
  }
  .bigImage{
    width: 100%;
    .taggedImage{
      @include media.mediaWrapper16-9;
    
    
      }
      .media{
        @include media.mediaWrapper16-9;
      
      
        }
  }
}

.container{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 96px  calc(grid.desktopFauxColumnWidth(2) + grid.$desktopPadding + calc(grid.$desktopGap * 1));
  gap: calc(grid.desktopFauxColumnWidth(1) + calc(grid.$desktopGap * 2));

  &.large{
    padding: 120px  calc(grid.desktopFauxColumnWidth(2) + grid.$desktopPadding + calc(grid.$desktopGap * 1));
    margin: 56px 0;
  }






  .firstImage{
  
    @include grid.desktopFauxColumnSpanPlusGutter(4);
   
    .taggedImage{
      @include media.mediaWrapper4-5;
    
    
      }
      .media{
        @include media.mediaWrapper4-5;
      
      
        }
  }

  .secondImage{
    display: flex;
    align-items: center;
    justify-content: center;
 
    @include grid.desktopFauxColumnSpanPlusGutter(3);
    .taggedImage{
      @include media.mediaWrapper4-5;
    
    
      }
      .media{
        @include media.mediaWrapper4-5;
      
      
        }
  }
}
$firstImageWidthMobile: calc(grid.mobileFauxColumnWidth(2) + 15px);
$secondImageWidthMobile: calc(grid.mobileFauxColumnWidth(2) - 15px);
@include breakpoint.mobile{
  .container{
    padding: 32px  20px;
    width: 100vw;
    gap: 15px;
    &.large{
      padding: 32px  20px;
      margin: 24px 0;
    }
    .firstImage{
     width: $firstImageWidthMobile;
     min-width: $firstImageWidthMobile;
      max-width: $firstImageWidthMobile;
      .taggedImage{
        @include media.mediaWrapper4-5;
      
      
        }
        .media{
          @include media.mediaWrapper4-5;
        
        
          }
    }
    .secondImage{
     width: $secondImageWidthMobile;
      min-width: $secondImageWidthMobile;
      max-width: $secondImageWidthMobile;
     
      align-items: flex-start;
      justify-content: center;
      &.flip{
       
        align-items: flex-end;
      }
      .taggedImage{
        @include media.mediaWrapper4-5;
      
      
        }
        .media{
          @include media.mediaWrapper4-5;
        
        
          }
    }
  }
  .bigImageContainer{
    display: flex;
    padding: 32px  20px;
    width: 100vw;
    &.large{
      padding: 32px  20px;
      margin: 24px 0;
    }
    .bigImage{
      
      .taggedImage{
        @include media.mediaWrapper3-2;
      
      
        }
        .media{
          @include media.mediaWrapper3-2;
        
        
          }
    }
  }
}