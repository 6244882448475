@use "@/styles/_colors";

.container {
  display: block;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  opacity: 0%;
  animation: fadeOut 300ms ease-in-out forwards;
  &.open {
    background: colors.$modal;
    opacity: 100%;
    animation: fadeIn 300ms ease-in-out forwards;
   
    
    
  }


}
@keyframes fadeIn {
  0% {
    opacity: 0;
    filter: blur(2px);
  }
  100% {
    opacity: 1;
    filter: blur(0px);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    filter: blur(0px);
  }
  100% {
    opacity: 0;
    filter: blur(2px);
  }
}