@use "@/styles/_grid";
@use "@/styles/_colors";
@use "@/styles/_breakpoint";
@use "@/styles/_typography";

.wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .container{
    background-color: colors.$sand ;
    position: relative;
    display: flex;
    padding:  56px 40px 48px 40px;
    gap: 24px;
      flex-direction: column;
    width: calc(grid.desktopFauxColumnWidth(4) + (2 * grid.$desktopGap));
    .title{
    @include typography.h20-16(true);

    }
    .text{
      @include typography.b16-14(true)

    }
    .close {
      @include typography.b16-14(true);
      position: absolute;
      top: 17px;
      right: 24px;

    }
  }
}

  @include breakpoint.mobile {
    .wrapper{
    .container{
      width: calc(grid.mobileFauxColumnWidth(4) + (2 * grid.$mobileGap ) - 20px);
      .close {
        
        position: absolute;
        top: 12px;
        right: 12px;
      
      }
    }
  }
}