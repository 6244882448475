@use "@/styles/_grid";
@use "@/styles/_breakpoint";



.grid {
  @include grid.mainGrid;
  padding-bottom:64px;

  .innerGrid {
    grid-column-start: 2;
    grid-column-end: 12;
    display: grid;
    grid-template-columns: repeat(3, grid.desktopFauxColumnWidth(3));
    justify-content: space-between;
    row-gap: 64px;
    flex-wrap: wrap;
  }
}

@include breakpoint.mobile {
  .grid {
    .innerGrid {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-template-columns: 1fr;
      gap: 32px;
    }
  }
}

