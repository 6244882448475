@use "@/styles/grid";
@use "@/styles/typography";

.container {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}

.header {
  display: flex;
  justify-content: center;
  padding: 96px;
  height: fit-content;
  .title {
    @include typography.h20-16(true);
    display: flex;

    span {
      @include typography.b14-12;
      margin-left: 4px;
    }
  }
}