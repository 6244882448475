@use "@/styles/_colors";
@use "@/styles/_grid";
@use "@/styles/_media";
@use "@/styles/_breakpoint";
@use "@/styles/_typography";

.container{
  
  padding: 16px 0px;
  border-bottom: 1px solid colors.$black01;

  summary{
    &::marker{
      padding-left:0;
      background-image: none;
     
    }
    &::-webkit-details-marker{
      display: none;
    }
  }
  .title{
    @include typography.b16-14;
    display: flex;
    justify-content: space-between;
    transition: margin 200ms ease-out;;
    &.active{
      
      margin-bottom: 18px;
    }
   
  }
  &:hover{
    cursor: pointer;
    
      opacity: var(--hover-opacity);
  
  }
}

