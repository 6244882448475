
@use "@/styles/_colors";
@use "@/styles/_typography";

.button{
  @include typography.b16-14;
  padding: 8px 20px;
  background-color: colors.$brown;
  color: colors.$sand;
  border-radius: 2px;
  display: flex;
  width: max-content;
}