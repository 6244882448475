@use "@/styles/_colors.scss";
@use "@/styles/_grid.scss";
@use "@/styles/_media.scss";
@use "@/styles/_breakpoint.scss";
@use "@/styles/_typography.scss";

.container{

width: 100%;

  display: flex;
  flex-direction: column;

  }
  .text{
    @include typography.h32-22;
   
  }
  .nameContainer{
    margin-top: 12px;
    @include typography.b16-16;
    gap: 8px;
    display: flex;
    flex-direction: row;
    .role{
      color: colors.$black50;
    }


   
  }



