@use "@/styles/_colors";

.input {
  min-width: 100%;
  border: none;
  border-bottom: 1px solid colors.$black;
  background-color: colors.$sand;
  border-radius: 0px;
  padding: 20px 15px 0px 0px;

  font-weight: 500;
  &.smallPlaceholder {
    padding: 20px 15px 8px 6px;
  }
  &::placeholder {
    color: colors.$black;
    font-weight: 500;
  }

  &.invalid {
    border-bottom: 1px solid colors.$red;
  }
}
.label {
  display: flex;
  position: relative;
  &.disabled {
    background-color: colors.$sand;
  }
  .message {
    position: absolute;
    display: none;
    bottom: -15px;
    font-size: 12px;
    margin-top: 5px;
    &.success {
      color: colors.$green;
      display: inline;
    }
    &.error {
      color: colors.$red;
      display: inline;
    }
  }
  .placeholder {
    pointer-events: none;
    &.disabled {
      color: colors.$teal;
    }

    position: absolute;
    bottom: 8px;

    left: 6px;
    color: colors.$teal;
    transition: all 0.3s ease;
    &.smallPlaceholder {
      bottom: 25px;
      left: 0px;
      top: 0;
      font-size: 10px;
      line-height: 1.5;
      .required {
        position: absolute;
        right: -5px;
        color: colors.$red;
      }
    }

    .required {
      position: absolute;
      right: -9px;
      color: colors.$red;
    }
  }
}
