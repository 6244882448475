@use "@/styles/_colors";
@use "@/styles/_grid";
@use "@/styles/_media";
@use "@/styles/_breakpoint";
@use "@/styles/_typography";

.giftcard-app-container {
  --_page-padding-inline: calc(max(48px, (100vw - 2560px) / 2));
  --_py-top: 80px;
  --_py-bottom: 64px;
  --_gap: 30px;
  --_small-gap: 15px;

  --_color-invisible: #ffffff00;

  padding-inline: var(--_small-gap);
  padding-top: var(--_py-top);
  padding-bottom: var(--_py-bottom);

  & main {
    display: flex;
    flex-direction: column;
    gap: var(--_gap);
  }

  & .giftcard-form {
    display: flex;
    flex-direction: column;
    gap: var(--_gap);

    /* input wrapper */
    & div:has(label):not(.checkbox) {
      position: relative;
      display: flex;
      font-size: 0;
      & * {
        font-size: 16px;
      }
      & label {
        pointer-events: none;
        position: absolute;
        bottom: 8px;
        left: 6px;
        color: #676767;
        transition: all 0.3s ease;
      }
      & textarea::placeholder {
        transition: all 0.3s ease;
      }
      &:has(input:focus) label,
      &:not(:has(input[value=""])):not(:has(textarea[value=""])) label {
        opacity: 0;
      }
      & textarea:focus::placeholder {
        opacity: 0;
      }

      &:has(input:required) label::after {
        content: "*";
        color: colors.$red;
        position: absolute;
        left: calc(100% + 2px);
        top: 0;
      }

      & .error {
        position: absolute;
        font-size: 12px;
        color: #e60000;
      }
    }

    & .multi {
      gap: 20px;
      flex-wrap: wrap;

      @media (max-width: 950px) {
        & div {
          width: 100%;
        }
      }
    }

    

    & .checkbox {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      flex-wrap: wrap;
      input[type="radio"] {
        opacity: 0;
        width: 1px;
        height: 1px;
        min-width: 0;
        position:absolute;
       
      }

      

      & label {
        border: 2px solid #ddd;
        padding: 10px;
        cursor: pointer;
      }

      & label.selected {
        border-color: #000;
      }
    }
  }

  .anyAmount {
    min-width: auto;
    padding: 0 !important;
    padding-right: 10px;
   width: auto;
   display: flex;
    height: auto;
    opacity: 100;
    position: relative;
    
    &:not(:focus){
      text-indent: 0;
      width: 7ch;
      border-bottom: none;
      text-align: right;

    }
  }

  .anyAmountLabel{
    display: flex;
    align-items: center;
    gap: 5px;
  }

  & h3 {
    @include typography.h24-20;
    padding-bottom: var(--_small-gap);
  }

  & input,
  & textarea {
    min-width: 100%;
    border: none;
    border-bottom: 1px solid colors.$black;
    background-color: colors.$sand;
    border-radius: 0px;
    padding: 20px 9px 6px 0px;
    text-indent: 6px;
    font-weight: 500;

    &:not([value=""]):invalid {
      border-bottom: 1px solid colors.$red;
    }
  }

  & button {
    padding: 12px 75px;
    @include typography.b16-14;
    background-color: colors.$brown;
    color: colors.$sand;
    border-radius: 2px;
    display: flex;
    width: max-content;
  }
}


.GiftcardApp .item-preview p {
  border: 2px solid #000;
  padding: 10px;
  display: inline-block;
  margin-bottom: 16px;
}