@use "@/styles/_devColors.scss";

.icon {
  position:static;
  z-index: 999;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: devColors.$darkPurple;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 4px;

  .column {
    width: 100%;
    height: 100%;
    background-color: devColors.$lightPurple;
  }

  &.active {
    .column {
      background-color: devColors.$brandOrange;
    }
  }
}
