@mixin mediaWrapper($width, $height) {
  margin: 0;
  box-sizing: border-box;
  position: relative;
  min-height: auto;
  width: 100%;
  padding-bottom: $height / $width * 100%;
}

@mixin mediaWrapper4-5{
  @include mediaWrapper(4, 5);
}

@mixin mediaWrapper3-2 {
  @include mediaWrapper(3, 2);
}

@mixin mediaWrapper16-9 {
  @include mediaWrapper(16, 9);
}