@use "@/styles/_grid.scss";
@use "@/styles/_colors.scss";
@use "@/styles/_breakpoint.scss";
@use "@/styles/_typography.scss";
@use "@/styles/_media.scss";
@use "@/styles/animations.scss";

  .container {
    position: relative;
    width: 100%;
    min-height: fit-content;
    z-index: 100;
    display: flex;
    padding: 0 47px;

    .mainContent {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding-left: calc(grid.desktopFauxColumnWidth(1) + grid.$desktopPadding + grid.$desktopGap);
      padding-top: 128px;
      padding-bottom: 128px;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 27px;
    &.top{
      justify-content: flex-start;
    }
    &.bottom{
      justify-content: flex-end;
    }
      
      z-index: 110;
      .text{
        @include typography.h32-22(true);
        @include grid.desktopFauxColumnSpan(4);
        color: colors.$white;
        text-decoration: none;
        animation: fadeInBottomSmall 800ms both;
        animation-delay: 600ms;
        pointer-events: all;
      }
      .link{
        @include typography.h32-22(true);
        color: colors.$white;
        text-decoration: underline;
        text-underline-offset: 1rem;
        transition: all 250ms;
        transform: skew(0);
        display: inline-block;
        animation: fadeInBottomSmall 800ms both;
        animation-delay: 700ms;
        pointer-events: all;
        &:hover {
          transition: opacity 50ms;
          opacity: var(--hover-opacity)
        }
      }
    }

    .imageWrapper {
      @include media.mediaWrapper16-9;
      animation: fadeIn 500ms both;
      animation-delay: 500ms;
    }
  }



@include breakpoint.mobile {

    .container {
      
     flex-direction: column;
     height: max-content;
     padding:24px grid.$mobilePadding;
     
      .mainContent {
        position: relative;
        padding-left: 0;
        padding-top: 26px;
        padding-bottom: 0;
        
        gap: 12px;
       order: 1;
        .text{
          @include grid.mobileFauxColumnSpan(4);
          color: colors.$black;
        }
        .link{
          @include grid.mobileFauxColumnSpan(4);
          color: colors.$black;
        }
      }
      .imageWrapper{
        @include media.mediaWrapper3-2;
        
      }
     
    }
  }
