@use "@/styles/_colors";
@use "@/styles/_typography";
@use "@/styles/_breakpoint";
@use "@/styles/_grid";
.grid{
  @include grid.mainGrid;
  padding-top: 108px;
  padding-bottom: 122px;
 
width: 100%;
.container{
  grid-column: 2 / span 5;
  &.left{
    grid-column: 2 / span 5;
  }
  &.right{
    grid-column: 7 / span 5;
  }
  &.center{
    grid-column: 5 / span 5;
  }
  .title{
    @include typography.h24-20;
    color: colors.$black;
    padding-bottom: 6px;

  }
  .button{
    margin-top: 16px;
    text-decoration: underline;
  }
}
}

@include breakpoint.mobile{
  .grid{
    padding-top: 32px;
    padding-bottom: 40px;

  .container{
    grid-column: span 4;
    &.left, &.right, &.center{
      grid-column: span 4;
    }
   
  }
}
}