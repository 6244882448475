@use "@/styles/_colors";

.container {
  box-sizing: border-box;
  display: block;

  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;

}