@use "@/styles/_colors";
@use "@/styles/_grid";
@use "@/styles/_media";
@use "@/styles/_breakpoint";
@use "@/styles/_typography";
.container{
  height: inherit;
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  
  gap:5px;
  .title{
    @include typography.b16-14;
  }
  .link{
    @include typography.b14-12;
  }
}


@include breakpoint.mobile {
  .container{
    grid-column: span 4;
    justify-content: center;
    .itemsContainer{
      display: flex;
      flex-direction: column;
      gap: 5px;
      
  }
}
}