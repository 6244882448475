

@use "@/styles/_typography.scss";
@use "@/styles/_breakpoint.scss";











@include breakpoint.desktop {
  .product-card:hover .pc-wishlist-container{
  display: flex;
  
}
.product-card:hover .pc-swatches-container{
  display: flex;
 
}

.product-card:hover .pc-count-container{
  display: none;
  
  
}

.product-card:hover  .pc-details-container{
  grid-row-start: 2;
   
  h4, h3 {
    @include typography.b12-12;
  }

}

}