
@use "@/styles/_colors";

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  border: none;
}
.imageWrapper {
  margin: 0;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding-bottom: 133%;
  background-color: colors.$gray;
}

.detailsWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  margin-top: 15px;
  background-color: colors.$gray;



  .priceVariantContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .price {

    font-weight: 600;
    line-height: normal;
  }
}
.shimmerBG {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
}
@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
