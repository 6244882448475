@use "@/styles/_colors";
@use "@/styles/_grid";
@use "@/styles/_media";
@use "@/styles/_breakpoint";
@use "@/styles/_typography";
.container{
  display: flex;
  align-items: center;
  padding-left: calc(grid.desktopFauxColumnWidth(2) + grid.$desktopPadding + calc(grid.$desktopGap * 1));
  padding-right: calc(grid.desktopFauxColumnWidth(2) + grid.$desktopPadding);
  padding-top: 108px;
  padding-bottom: 114px;
  gap: calc(grid.desktopFauxColumnWidth(1) + grid.$desktopGap);

  .mediaWrapper{
    @include  grid.desktopFauxColumnSpan(4);
    display: flex;
    .media{
      @include media.mediaWrapper4-5
    }
    &.first{
      order: -1;
    }
    &.second{
      order: 1;
    }
   
  }
  .textWrapper{
    width: calc(grid.desktopFauxColumnWidth(3) + calc(grid.$desktopGap * 2));
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    

    .title{
      @include typography.h32-22;
     
      margin-bottom: 12px;
    }
    .subtitle{
      @include typography.b14-12;
      margin-bottom: 6px;
    }

    // .richText{

    // }
    &.first{
      order: -1;
    }
    &.second{
      order: 1;
    }
  }
}



@include breakpoint.mobile {
  .container{
    padding: 24px 20px;
    gap: 20px;
    flex-direction: column;
    .mediaWrapper{
      @include  grid.mobileFauxColumnSpan(4);
      &.first{
        order: -1;
      }
      &.second{
        order: -1;
      }
    }
    .textWrapper{
      @include  grid.mobileFauxColumnSpan(4);
      &.first{
        order: 1;
      }
      &.second{
        order: 1;
      }
    }
  }

}