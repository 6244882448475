@use "@/styles/_breakpoint";
@use "@/styles/_colors.scss";
@use "@/styles/_media.scss";
.container {
  grid-column: span 3;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .imageWrapper {
    @include media.mediaWrapper4-5;
  }

  .details {
    width: 100%;
  }
}

a.link {
  text-decoration: none;
}

@include breakpoint.mobile {
  .container {
    grid-column: span 2;
  }
}