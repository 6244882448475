@use "@/styles/_colors.scss";
@use "@/styles/_grid.scss";
@use "@/styles/_media.scss";
@use "@/styles/_breakpoint.scss";
@use "@/styles/_typography.scss";

.container{
  @include grid.mainGrid;
  padding-top: 120px;
  padding-bottom: 90px;

.textHeader{
  display: flex;
  flex-direction: column;
  grid-column: 2 / span 3;
}
}

@include breakpoint.mobile {
  .container{
    padding: 32px 20px;
    .textHeader{
    
      grid-column: span 4;
   
  }
  }
}