@use "@/styles/_breakpoint";
@use "@/styles/_grid";
@use "@/styles/_colors";
@use "@/styles/_typography";

.list {
  padding: 0 calc(grid.desktopFauxColumnWidth(1) + grid.$desktopPadding + 30px);

  .retailer,
  .header {
    @include grid.mainGrid;
    grid-template-columns: repeat(10, 1fr);
    border-bottom: 1px solid colors.$black01;

    .cell {
      grid-column: span 2;
    }
  }

  .retailer {
    padding: 26px 0 35px;

    .cell {
      @include typography.b16-14;
      margin-top: 4px;

      &:first-child {
        @include typography.h20-16;
        margin-top: 0;
      }

      a {
        text-decoration: underline;
      }
    }
  }

  .header {
    padding: 0 0 19px;
    
    .cell {
      @include typography.b14-14;
      color: colors.$teal;

      a {
        text-decoration: underline;
      }
    }
  }
}

@include breakpoint.mobile {
  .list {
    padding: 0 20px;

    .header {
      display: none;
    }

    .retailer {
      .cell {
        grid-column: span 2;

        &:first-child {
          grid-column: span 4;
        }
      }
    }
  }
}