@use "@/styles/_colors.scss";
@use "@/styles/_typography.scss";
@use "@/styles/_breakpoint.scss";
@use "@/styles/_grid.scss";

;

.container {
  &.two{
  padding: 96px calc(grid.$desktopPadding + grid.desktopFauxColumnWidth(3));
  }
  &.three{
  padding: 96px calc(grid.$desktopPadding + grid.desktopFauxColumnWidth(1) + grid.$desktopGap);
  }
  width: 100%;
 
  display: flex;
  flex-direction: column;
  gap: 61px;
  .title{
    @include typography.h32-22;
    text-align: center;
    display: block;
    width: 100%;
    text-decoration: none;
    
   
  }
  a{
    &.title{
    &:hover{
      
      text-decoration: underline;
    }
  }
  }


  .productCard{
    width: grid.desktopFauxColumnWidth(4);
  }
  .cardList{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: grid.desktopFauxColumnWidth(1);
  }
}

@include breakpoint.mobile {
  .container {
    // &.two{
    //   padding: 32px grid.$mobilePadding;
    //   padding-right: 0;
     
    //   .cardList{
    //     padding-right: 0;
    //     gap: 15px;
    //   overflow: auto;}
    //   &.bigCards{
    //     .cardList{
    //       .productCard{
          
    //          @include grid.mobileFauxColumnSpan(3);
             
            
    //        }
    //       }
    //   }
    //   }
    //   &.three{
    //   padding: 32px grid.$mobilePadding;
    //   padding-right: 0;
    //   &.bigCards{
    //     .cardList{
    //       overflow: auto;
    //       align-items: center;
    //       justify-content: center;
    //       flex-wrap: wrap;
    //       gap: 15px;
    //       row-gap: 24px;
    //   .productCard{
    //     &.first{
    //      @include grid.mobileFauxColumnSpan(3);
         
    //     }
    //    }
    //   }
    //   }
    //   }
    &.two, &.three{
      padding: 32px grid.$mobilePadding;
      padding-right: 0;
    }

    

      gap: 24px;
      
    .cardList{
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 10px;
      overflow: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
 padding-right: grid.$mobilePadding;   
    }
    .productCard{
     @include grid.mobileFauxColumnSpan(3);
    
    }
    
      
    }
  }