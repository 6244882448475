@use "@/styles/_typography.scss";
@use "@/styles/_colors.scss";
@use "@/styles/_breakpoint.scss";
@use "@/styles/_grid.scss";

.wrapper {
  width: 100%;
}

.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(2, auto);
  width: 100%;
  max-width: 100%;
  column-gap: 1rem;
}

.nameContainer {
  grid-column-start: 1;
  grid-row-start: 1;
  display: flex;
  gap: 0.5rem;
  .productName, .productType {
    @include typography.b14-14;
  }
  .productName {
    width: fit-content;
    color: colors.$black;
  }

  .productType {
    color: colors.$teal;
  }

  

  @include breakpoint.mobile {
    grid-column-end: 3;
  }
}

.productPrice {
  grid-column-start: 2;
  grid-row-start: 1;
  justify-self: flex-end;
  @include typography.b14-12;
  &.mobile {
    grid-row-start: 2;
  }
}

.countContainer {
  @include typography.b12-12;
  grid-row-start: 2;
}

.variantName {
  @include typography.b12-12;
}

.swatchesContainer {
  grid-column-start: 1;
  grid-row-start: 1;
  display: none;
  align-items: center;
  gap: 5px;
  height: 21px;
  .swatchContainer {
    min-height: 12px;
    min-width: 12px;
    width: fit-content;
    height: fit-content;
  }

  .swatch {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 1px solid colors.$borderGrey;
    &:hover {
     border: solid colors.$black 1px;
    }
  }

  .swatchActive {
    height: 16px;
    width: 16px;
    padding: 1px;
    border-radius: 50%;
    box-sizing: border-box;
    border: solid colors.$black 1px;
    .swatch {
      &:hover{
        border: 1px solid colors.$borderGrey;
      }
    }
    
  }
  
}

.wishlist {
  display: none;
  grid-row: 2 / span 1;
  grid-column-start: 2 / span 1;
  justify-content: flex-end;
  @include typography.b12-12;
  &.remove{
    button {
      color: colors.$red;
    }
  }
  @include breakpoint.mobile {
    justify-content: flex-start;
    grid-row: 3 / span 1;
  }
}