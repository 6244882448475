@use "@/styles/_breakpoint";
@use "@/styles/_grid";
@use "@/styles/_colors";
@use "@/styles/_typography";

.container {
  @include grid.mainGrid;
  flex-direction: column;
  gap: 16px;

  .wrapper {
    padding: 120px 0 90px;
    grid-column: 2 / 6;
    display: flex;
    flex-direction: column;
    gap: 20px;
  
    div {
      box-sizing: border-box;
    }
  
    h1 {
      @include typography.h24-20;
      margin-bottom: 17px;
    }
  
    p {
      @include typography.b16-14;
    }

    .filterContainer {
      margin-top: 17px;
    }
  }

  .categoryContainer {
    display: flex;
    gap: 16px;
    @include typography.b16-14;

    p {
      text-decoration: underline;
      text-underline-offset: 4px;
    }

    a {
      @include typography.b16-14;
      text-decoration: none;
      &:hover {
     
          opacity: var(--hover-opacity);
          cursor: pointer;
        
      }
    }
  }
}

@include breakpoint.mobile {
  .container {
    padding-top: 12px;
    padding-bottom: 12px;

    .wrapper {
      padding: 20px 0;
      grid-column: 1 / 5;
    }

    main {
      grid-column: span 4;
    }
  }
}
