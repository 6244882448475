@use "@/styles/_breakpoint";
@use "@/styles/_grid.scss";
@use "@/styles/_colors.scss";
@use "@/styles/typography";

.locationSelector{
  padding: 20px;
}

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: colors.$modal;
  z-index: 9999;

  .content {
    width: calc(100% - 40px);
    min-height: 100vh;
    height: 100%;
    background-color: colors.$warmGrey;
    line-height: 32px;
  }

  .close {
    padding: 20px;
    height: 60px;
    line-height: 1;
    @include typography.b14-14;
  }

  .labels {
    color: colors.$black08;
    padding: 20px 20px 0;
    display: flex;
    gap: 15px;

    .current {
      text-decoration: underline;
      color: colors.$black;
    }
  }
}

@include breakpoint.mobile { 
  .container{

    .close {
      height: fit-content;
    }
  }
}