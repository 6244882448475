@use "@/styles/_breakpoint";
@use "@/styles/_grid";
@use "@/styles/_colors";
@use "@/styles/_typography";

.filter {
  position: relative;
  width: min-content;
  select {
    appearance: none;
    padding: 11px 14px;
    width: 250px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    background: none;
    border: 1px colors.$black solid;
  }
  svg{
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@include breakpoint.mobile {
  .filter {
    display: none;
  }
}