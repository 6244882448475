@use "@/styles/_colors.scss";
@use "@/styles/_grid.scss";
@use "@/styles/_media.scss";
@use "@/styles/_breakpoint.scss";
@use "@/styles/_typography.scss";
@use "@/styles/animations.scss";

.container{
  @include grid.mainGrid;
  padding-top: 108px;
  padding-bottom: 114px;
  animation: fadeInBottomFixed 700ms both;
  animation-delay: 100ms;

.textContainer{
  display: flex;
  flex-direction: column;
  &.left{
    grid-column: 2 / span 5;
  }
  &.center{
    grid-column: 5 / span 5;
  }
  &.right{
    grid-column: 7 / span 5;
  }
  .text{
    @include typography.h32-22;
   
  }
  .nameContainer{
    @include typography.b16-16;
    gap: 8px;
    display: flex;
    flex-direction: row;
    .role{
      color: colors.$black50;
    }


   
  }
}
}

@include breakpoint.mobile {
  .container{
    padding: 32px 20px;
    .textContainer{
      &.left, &.center, &.right{
      grid-column: span 4;
    }
  }
  }
}