@use "@/styles/_colors";
@use "@/styles/_grid";
@use "@/styles/_media";
@use "@/styles/_breakpoint";
@use "@/styles/_typography";
.container{
  display: flex;
  gap: 32px;
  flex-direction: column;
  h1{
    @include typography.h40-32(true);
  }
  h2{
    @include typography.h32-22(true);
  }
  h3{
    @include typography.h24-20(true);
  }
  h4{
    @include typography.h20-16(true);
  }
  p{
    @include typography.b16-14(true);
  }

  &.productPage {
    p {
      @include typography.b14-12(true);
    }
  }
.link{
  @include typography.b16-14(true);
  display: inline;
  text-decoration: underline;

  &.productPage {
    @include typography.b14-12(true);
  }
  
}
.space{
  margin-bottom: 32px;
}

.list{
  @include typography.b16-14(true);
  list-style: inside;

  &.productPage {
    @include typography.b14-12(true);
  }
}
.listItem{
  p{
    display: inline;
  }
}
.button{
  @include typography.b16-14(true);
  padding: 8px 20px;
  background-color: colors.$brown;
  color: colors.$sand;
  border-radius: 2px;
  display: flex;
  width: max-content;
}
}