@use "./devColors";
$black: #292929;
$black05: #EDECE7; //lol
$black06: #7F7F7F;
$black08:  #545454;
$black50: #8E8E8E;
$black5: #E8E8E8;
$cream: #FFFEFA;
$white: #ffffff;
$gray: #e2e2e2;
$grey: $gray;
$teal: #676767;
$borderGrey: #BBBBBB;
$lightGrey: #e8e8e8;
$warmGrey: #F9F8F3;
$modal: rgba(0, 0, 0, 0.5);
$sand: #FFFEFA;
$brown: #230C00;
$button: #230C00;
$black01: #2929290a;
$red: #A43232;
$green: #278E4A;
$black70: $teal;

$brownHover: #230c0096;
$buttonClick: #230c004d;

:export {
  black: $black;
  black05: $black05;
  black06: $black06;
  black08: $black08;
  black50: $black50;
  black5: $black5;
  cream: $cream;
  white: $white;
  gray: $gray;
  grey: $grey;
  teal: $teal;
  borderGrey: $borderGrey;
  lightGrey: $lightGrey;
  warmGrey: $warmGrey;
  modal: $modal;
  sand: $sand;
  brown: $brown;
  button: $button;
  black01: $black01;
  transparent: rgba(0, 0, 0, 0);
  
}
$secondary: devColors.$darkPurple;
$text: $black;
