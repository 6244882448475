@use "@/styles/_grid";
@use "@/styles/_media";
@use "@/styles/_breakpoint";
@use "@/styles/_typography";

.grid {
  @include grid.mainGrid;
  row-gap: 28px;
}

.featureContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageContainer {
  @include grid.desktopFauxColumnSpanPlusBothGutters(4);
}

.imageWrapper {
  @include media.mediaWrapper4-5;
}

.getMore{
  grid-column: span 12;
  
  display: flex;
  align-items: center;
  justify-content: center;
  &.loading{
    padding: 64px 0;
    p{
      @include typography.b14-12(true)
    }
  }
}

@include breakpoint.mobile {

  .featureContainer {
    height: min-content;
  }

  .grid{
    row-gap: 20px;
  }
  .getMore{
    grid-column: span 4;
    padding: 32px 0;
  }

  .imageContainer {
    @include grid.mobileFauxColumnSpan(4);
  }
}
