@use "@/styles/_grid.scss";
@use "@/styles/_breakpoint.scss";
@use "@/styles/_media.scss";
@use "@/styles/_typography.scss";

.container{
@include grid.mainGrid;
padding-top: 96px;
row-gap: 96px;
padding-bottom: 96px;

.text{
  @include typography.h32-22;
  grid-column: 2/ span 4;
  grid-row: 1;
  padding-top: 40px;
  &.zero{
    padding-bottom: 48px;
  }
  .link{

    text-decoration: underline;
    cursor: pointer;
    &:hover{
      font-style: italic;
    }
  }
}
.firstImage{
 
  &.one{
    grid-column: 4/ span 7;
    grid-row: 2;
  }
  &.two{
    grid-column: 2/ span 6;
    grid-row: 2;
  }
  &.three{
    grid-column: 7/ span 5;
    grid-row: 1 / span 2;
  }
  .taggedImage{
    @include media.mediaWrapper3-2;
  
  
    }
    .media{
      @include media.mediaWrapper3-2;
    
    
      }
}

.secondImage{
  grid-column: 9/ span 3;
  grid-row: 1 / span 2;
  &.three{
    grid-column: 2/ span 3;
    grid-row: 2;
  }
  .taggedImage{
    @include media.mediaWrapper4-5;
  
  
    }
    .media{
      @include media.mediaWrapper4-5;
    
    
      }
}

.thirdImage{
  grid-column: 6/ span 2;
  margin-top: -200px;
  grid-row: 3;
  .taggedImage{
    @include media.mediaWrapper4-5;
  
  
    }
    .media{
      @include media.mediaWrapper4-5;
    
    
      }
}

}

@include breakpoint.mobile{
  .container{
    padding-top: 64px;
    padding-bottom: 24px;
    row-gap: 24px;
    .text{
    grid-column: span 4;
    grid-row: 1;
    &.zero{
      padding-bottom: 0px;
    }
    &.one{
      padding-bottom: 8px;
    }
    }
    .firstImage{
     
      &.one{
        grid-column: span 4;
        padding: 0px 15px;
        grid-row: 2;
      }
      &.two{
        grid-column:  span 3;
        grid-row: 3;
      }
      &.three{
        grid-column:  span 4;
        grid-row: 3;
      }
    }
    .secondImage{
      grid-column: 3 /span 2;
      grid-row: 2;
      &.three{
        grid-column: span 2;
       
      }
    }
  }
}

